import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { VideoBg } from "../video-bg";

const videoScreenHeader = ["os"];

export const Container = (props: any) => {
  const { children, from, theme } = props;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  const getHeaderStyle: any = () => {
    if (from === "onets") {
      return {
        backgroundImage: 'url("/assets/map.jpeg")',
        backroundSize: "cover",
        backgroundPosition: "50%",
      };
    }

    if (from === "n3-ceramics") {
      return {
        backgroundImage: 'url("/assets/ceramics/bg.webp")',
        backroundSize: "cover",
        backgroundPosition: "50%",
      };
    }

    return {};
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      {videoScreenHeader.indexOf(from) < 0 && (
        <div
          className={`h-[120px] w-full max-w-[100%] sm:max-w-[640px] bg-gradient-to-r ${
            from === "octopus"
              ? "from-[#fdad1d] to-[#fffa0a]"
              : "from-cyan-500 to-blue-500"
          } mx-auto`}
          style={getHeaderStyle()}
        ></div>
      )}
      {/* If from OS */}
      {from === "os" && <VideoBg />}
      <div
        className={`m-auto max-w-[100%] sm:max-w-[640px] bg-cover relative flex flex-1 justify-center p-[20px] shadow-md ${
          theme === "dark" ? "bg-[#2E2D4D]" : "bg-white"
        } overflow-hidden overflow-y-scroll`}
        id="home-container"
        style={{
          height: "calc(100vh - 120px)",
        }}
      >
        {children}
      </div>
    </div>
  );
};
