import * as React from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { Home } from "./pages/home";
import { PaymentError } from "./pages/error";
import { Wechat } from "./pages/wechat";
import { Success } from "./pages/payment-success";
import { WechatPaymentError } from "./pages/wechat-error";
import { WechatPaymentSuccess } from "./pages/wechat-success";
import { OsHome } from "./pages/os-home";
import { Demo } from "./pages/demo";
import { MiddleEast } from "./pages/middle-east";
import { Octopus } from "./pages/octopus";
import { OctopusSuccess } from "./pages/octopus-success";
import { Onets } from "./pages/onets";
import { OnetsSuccess } from "./pages/onets-success";
import { OnetsConfirmReceipt } from "./pages/onets-confirm-receipt";
import { N3Ceramics } from "./pages/n3-ceramics";
import { N3Ceramicsuccess } from "./pages/n3-ceramics-success";
import { N3CeramicsConfirmReceipt } from "./pages/n3-ceramics-confirm-receips";

export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        <Route path={"/"} exact component={Home} />

        <Route path={"/octopus"} exact component={Octopus} />

        <Route path={"/wechat"} exact component={Wechat} />

        {/* Octopus */}
        <Route
          path={"/octopus-checkout-success"}
          exact
          component={OctopusSuccess}
        />

        <Route path={"/checkout-success"} exact component={Success} />
        <Route
          path={"/wechat-checkout-success"}
          exact
          component={WechatPaymentSuccess}
        />
        <Route path={"/error"} exact component={PaymentError} />
        <Route path={"/wechat-error"} exact component={WechatPaymentError} />

        {/* Demo */}
        <Route path={"/demo"} exact component={Demo} />

        {/* One Satoshi */}
        <Route path={"/one-satoshi"} exact component={OsHome} />

        {/* Middle East */}
        <Route path={"/middle-east"} exact component={MiddleEast} />
        <Route path={"/me"} exact component={MiddleEast} />

        {/* Onets */}
        <Route path={"/onets"} exact component={Onets} />
        <Route path={"/onets-success"} exact component={OnetsSuccess} />
        <Route
          path={"/onets-confirm-receipt"}
          exact
          component={OnetsConfirmReceipt}
        />

        {/* N3 Ceramics */}
        <Route path={"/n3-ceramics"} exact component={N3Ceramics} />
        <Route
          path={"/n3-ceramics-success"}
          exact
          component={N3Ceramicsuccess}
        />
        <Route
          path={"/n3-ceramics-confirm-receipt"}
          exact
          component={N3CeramicsConfirmReceipt}
        />

        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
