import React from "react";

export const ClientForm = (props: any) => {
  const {
    value,
    setValue,
    submit,
    bgColor = "#6018C3",
    txtColor = "#fff",
    labelColor = "#000",
    firstname,
    setFirstname,
    lastname,
    setLastname,
    phone,
    setPhone,
    street,
    setStreet,
    postalCode,
    setPostalCode,
    city,
    setCity,
    country,
    setCountry,
    amount,
    setAmount,
    method,
  } = props;
  return (
    <div>
      <p style={{ color: labelColor }}>Email*</p>
      <input
        type="email"
        name="email"
        className={$inputClass}
        placeholder=""
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />

      {amount && setAmount && (
        <>
          <p style={{ color: labelColor, marginTop: 5 }}>Amount*</p>
          <input
            type="number"
            name="amount"
            className={$inputClass}
            placeholder=""
            value={amount}
            onChange={(e) => {
              if (!e.target.value) return setAmount(1);
              setAmount(e.target.value);
            }}
          />
        </>
      )}

      <div className="grid grid-cols-2 gap-x-[10px]">
        <div>
          <p style={{ color: labelColor, marginTop: 5 }}>
            First Name{method === "unionpay" && "*"}
          </p>
          <input
            type="text"
            name="firstname"
            className={$inputClass}
            placeholder=""
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
        </div>

        <div>
          <p style={{ color: labelColor, marginTop: 5 }}>
            Last Name{method === "unionpay" && "*"}
          </p>
          <input
            type="text"
            name="lastname"
            className={$inputClass}
            placeholder=""
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
      </div>

      {method === "unionpay" && (
        <>
          <p style={{ color: labelColor, marginTop: 5 }}>Phone*</p>
          <input
            type="text"
            name="phone"
            className={$inputClass}
            placeholder="+85200000000"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <div className="grid grid-cols-2 gap-x-[10px]">
            <div>
              <p style={{ color: labelColor, marginTop: 5 }}>Street*</p>
              <input
                type="text"
                name="Street"
                className={$inputClass}
                placeholder=""
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </div>
            <div>
              <p style={{ color: labelColor, marginTop: 5 }}>PostalCode*</p>
              <input
                type="text"
                name="PostalCode"
                className={$inputClass}
                placeholder="0000"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
          </div>

          <p style={{ color: labelColor, marginTop: 5 }}>City*</p>
          <input
            type="text"
            name="City"
            className={$inputClass}
            placeholder=""
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </>
      )}

      <p style={{ color: labelColor, marginTop: 5 }}>
        Country{method === "unionpay" && "*"}
      </p>
      <input
        type="country"
        name="country"
        className={$inputClass}
        placeholder="iso3166 format (FR, GB)"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
      />

      {/* Submit Button */}
      <button
        className={`w-full mt-[20px] py-[10px] rounded-md`}
        onClick={submit}
        style={{ backgroundColor: bgColor, color: txtColor }}
      >
        Next
      </button>
    </div>
  );
};

const $inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";
