import React, { useEffect } from "react";
import { useLocation } from "react-router";

import { Container } from "src/components";
import { history } from "src/stores";

export const Octopus = (props: any) => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const orderId = searchParams.get("orderId") || "";
  const amount = searchParams.get("amount") || "";
  const merchant = searchParams.get("merchant") || "";

  useEffect(() => {
    if (!orderId || !amount || !merchant) return history.push("/error");
  }, [orderId, amount, merchant]);

  if (!orderId || !amount || !merchant) return null;

  return (
    <Container from="octopus">
      <div className="flex flex-col w-[100%] h-[100%]">
        {/* Title Row */}
        <div className="flex flex-row items-center justify-center">
          <p className="text-[16px] text-center text-[#334155]">Octopus</p>

          {/* Octopus Icon */}
          <div className="rounded-full overflow-hidden bg-white ml-[5px]">
            <img
              src="/assets/octopus.png"
              alt="octopus"
              className="w-[30px] object-fit"
            />
          </div>
        </div>

        {/* Body */}
        <div className="flex flex-col h-full items-center pt-[80px]">
          <p className="text-[20px] text-[#334155] pb-[50px] text-center w-full">
            HKD ${amount}
          </p>
          <p className="text-[11px] text-slate-300 text-center w-full">
            Order ID: {orderId}
          </p>

          <div className="w-full flex justify-center py-[50px]">
            <img
              src="/assets/secure-payment.png"
              alt="octopus-card"
              className="w-[170px] object-fit"
            />
          </div>

          <p className="text-[11px] text-[#334155] text-center w-full">
            Click Pay Now will redirect you to the payment guide
          </p>

          <div className="fixed bottom-[10%] rounded-full bg-gradient-to-r px-[50px] py-[11px] from-[#fdad1d] to-[#f65a17]">
            {/* Pay Now Button */}
            <form
              name="payForm"
              method="post"
              action={process.env.REACT_APP_MERCHANT_URL}
            >
              <input
                type="hidden"
                name="merchantId"
                value={process.env.REACT_APP_MERCHANT_ID}
              />

              {/* The Max amount is 5000 HKD */}
              <input type="hidden" name="amount" value={amount} />
              <input type="hidden" name="orderRef" value={orderId} />
              <input type="hidden" name="currCode" value="344" />
              <input type="hidden" name="payMethod" value="OCTOPUS" />

              <input
                type="hidden"
                name="successUrl"
                value={`${process.env.REACT_APP_RETURN_URL}/octopus-checkout-success`}
              />
              <input
                type="hidden"
                name="failUrl"
                value={`${process.env.REACT_APP_RETURN_URL}/error`}
              />
              <input
                type="hidden"
                name="errorUrl"
                value={`${process.env.REACT_APP_RETURN_URL}/error`}
              />
              <input type="hidden" name="lang" value="E" />
              <input
                type="submit"
                className="text-[#fff] font-bold"
                value="Pay Now"
              />
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};
