import React from "react";

export const SelectPayment = (props: any) => {
  const {
    method,
    setMethod,
    paymentMethod,
    bgColor = "#FFF",
    txtColor = "#000",
    activeBgColor = "#6018C3",
    activeTxtColor = "#FFF",
  } = props;

  const getName = (item: string) => {
    switch (item) {
      case "octopus":
        return "Octopus";
      case "pinpayments":
        return "Pin Payments";
      case "unionpay":
        return "Union Pay";
      default:
        return "Unknown";
    }
  };
  return (
    <div className="flex flex-row space-x-[15px]">
      {paymentMethod.map((item: any, i: any) => {
        return (
          <button
            className={`${$btnStyle}`}
            onClick={() => {
              setMethod(item);
            }}
            key={i}
            style={{
              backgroundColor: method === item ? activeBgColor : bgColor,
              color: method === item ? activeTxtColor : txtColor,
              borderColor: method === item ? activeBgColor : activeBgColor,
            }}
          >
            {getName(item)}
          </button>
        );
      })}
    </div>
  );
};
const $btnStyle = "w-full py-[10px] rounded-md text-[#000] border-[1px]";
