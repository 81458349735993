import axios from "axios";
import * as Configs from "../config";
import { isResultSuccess, setHeader } from "./api";

const _getMerhantUrl = (merchant: string) => {
  let merchantUrl = null;
  switch (merchant) {
    case "asiatop":
      merchantUrl = Configs.ASIATOP_API_URL;
      break;
    case "reverse":
    case "middle-east":
    case "os":
      merchantUrl = Configs.REVERSE_API_URL;
      break;
    case "onets":
      merchantUrl = Configs.ONETS_API_URL;
      break;
    case "n3-ceramics":
      merchantUrl = Configs.N3_CERAMICS_API_URL;
      break;
    default:
      break;
  }
  return merchantUrl;
};

export class PaymentStore {
  async checkPaymentOrder(payload: any, merchant: string) {
    const url = `${Configs.REVERSE_API_URL}/external-payment/${merchant}/check-order`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return false;
      return result["data"]["data"];
    } catch (e) {
      return false;
    }
  }

  async confirmPaymentOrder(payload: any, merchant: string) {
    const url = `${_getMerhantUrl(
      merchant
    )}/external-payment/${merchant}/confirm-order`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return false;
      return true;
    } catch (e) {
      return false;
    }
  }

  async confirmPaymentReceipt(payload: any, merchant: string) {
    const url = `${_getMerhantUrl(
      merchant
    )}/external-payment/${merchant}/confirm-receipt`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return false;
      return true;
    } catch (e) {
      return false;
    }
  }

  async createPaymentOrder(payload: any, merchant: string) {
    const url = `${_getMerhantUrl(
      merchant
    )}/external-payment/${merchant}/create-order`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return false;
      return result["data"]["data"];
    } catch (e) {
      return false;
    }
  }

  async sendNotification(payload: any, merchant: string) {
    const url = `${_getMerhantUrl(merchant)}/notification/payment-success`;
    try {
      const result: any = await axios.post(url, payload);
      if (!isResultSuccess(result)) return false;
      return true;
    } catch (e) {
      return false;
    }
  }

  async verifyPaymentSecret(payload: any, merchant: string) {
    const url = `${_getMerhantUrl(merchant)}/payment/verify-secret`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return false;
      return { msg: result["data"]["msg"], data: result["data"]["data"] };
    } catch (e) {
      return false;
    }
  }

  async updateEmailPaymentOrder(payload: any, merchant: string) {
    const url = `${_getMerhantUrl(merchant)}/external-payment/update-email`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!isResultSuccess(result)) return false;
      return true;
    } catch (e) {
      return false;
    }
  }
}

export const STORE_PAYMENT = "paymentStore";
